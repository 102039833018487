import { formatDate } from "../../utils/utils";
import Icon from "../Common/Icon";

export const PaymentDetails = ({ paymentData }) => {
    if (!paymentData) return;

    const paymentDetails = paymentData.transactions[0].payment;
    let paymentMethod;

    if (paymentDetails) {
        switch (paymentDetails.method) {
            case 'DIRECT_DEBIT': {
                paymentMethod = 'direct debit';
            }; break;
            case 'CARD': {
                paymentMethod = 'card';
            }; break;
            case 'TRANSFER': {
                paymentMethod = 'transfer';
            }; break;
            case 'BPAY': {
                paymentMethod = 'BPAY';
            }; break;
            case 'CASH': {
                paymentMethod = 'cash';
            }; break;
            case 'CHEQUE': {
                paymentMethod = 'cheque';
            }; break;
            default: {
            }
        }


        return (
            <>
                <div className="invoice-line-item">
                    <div className="line-item-label">Payment made{paymentMethod ? ` via ${paymentMethod}` : ''}</div>
                    <div className="line-item-value">
                        <div className="line-item-value-amount">${Math.abs(paymentDetails.amount)}</div>
                    </div>
                </div>
            </>
        )
    } else { //There's been a mistake somewhere andd this billing event is not a payment
        return 'foo'
    }
}