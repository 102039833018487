import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataContext, CommonDataProvider } from '../utils/CommonDataContext';
import { SessionContext } from '../utils/SessionContext';
import * as EnbraceApis from '../utils/enbraceAPIs'
import { CurrentPlanContent } from '../components/CurrentPlan/CurrentPlanContent'
import { DataProvider } from '../utils/DashboardDataContext';
import { AccordionContext } from '../utils/AccordionContext';

const CurrentPlan = () => {
    const { authenticatedUser } = useContext(AuthContext);
    const [selectedItem, setSelectedItem] = useState(null);

    if (authenticatedUser) {
        return (
            <Site>
                <CommonDataProvider>
                    <DataProvider>
                        <PageHeaderBar />
                        <AccordionContext.Provider value={{ selectedItem, setSelectedItem }}>
                            <CurrentPlanContent />
                        </AccordionContext.Provider>
                        <PageFooter />
                    </DataProvider>
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default CurrentPlan;
