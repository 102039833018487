import React, { useState, useContext, useRef, useEffect, cloneElement } from 'react';
import './AccordionGroup.scss'
import Icon from './Icon';
import { AccordionContext } from '../../utils/AccordionContext';


const AccordionGroup = ({ level, className, clickHandler, children }) => {
  // Determine the initial selected item based on the current window hash.
  const initialHash = window.location.hash ? window.location.hash.replace('#', '') : null;
  const {selectedItem, setSelectedItem} = useContext(AccordionContext); //useState(initialHash);

  useEffect(() => {
    setSelectedItem(initialHash);

    const handleHashChange = () => {
      console.debug(`Hash fragment of URL has changed to ${window.location.hash}`)
      const newHash = window.location.hash.replace('#', '');
      // Set the selected item based on the new hash.
      // If the hash is empty, set selectedItem to null, closing all items.
      setSelectedItem(newHash || null);
    };

    // Listen for hash changes to update the selected item accordingly.
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const handleAccordionClick = (id) => {
    if (selectedItem === id) {
      setSelectedItem(null); // Allow closing the currently open item
    } else {
      setSelectedItem(id);
    }
  };

  return (
      <div className={`accordion-group ${className || ''}`}>
        {(React.Children.count(children) > 0) ? React.Children.map(children, child => {
          if (child) return React.cloneElement(child, {
            isOpen: child.props.id === selectedItem,
            onClick: () => handleAccordionClick(child.props.id),
          })
        }
        )
          :
          <div className='accordion-details-unavailable'>No details available</div>
        }
      </div>
  );
};

const AccordionItem = ({ label, icon, id, children, isOpen, onClick, iconClass }) => {
  const contentRef = useRef(null);
  const [itemNowOpen, setItemNowOpen] = useState(false);

  useEffect(() => {
    console.debug(`Item (accordion-item-${id}) now ${itemNowOpen ? 'open' : 'closed'}`)
    if (isOpen) {
      // Scroll the accordion item into view
      const headerElement = document.getElementById(`accordion-item-${id}`);
      console.debug(`Header element of accordion item to scroll into view: `, headerElement)
      if (headerElement) {
        headerElement.scrollIntoView({
          behavior: 'smooth', // Smooth scroll
          block: 'center', // Scroll so that the accordion is aligned to the centre of the viewport
        });
      }
    }
  }, [itemNowOpen]);

  useEffect(() => {
    const contentElement = contentRef.current;

    const updateHeight = () => {
      console.debug(`Updating height`)
      //contentElement.style.height = 'auto';
      contentElement.style.height = isOpen ? `${contentElement.scrollHeight}px` : '0px';
    };

    const handleTransitionEnd = (event) => {
      if (event.propertyName === 'height' && isOpen) {
        console.log('Accordion item has finished opening');
        // Trigger any additional logic here
        setItemNowOpen(true);
      }
    };

    if (isOpen) {
      console.debug(`Opening item accordion-item-${id}`)
      updateHeight(); // Update for initial opening
    } else {
      contentElement.style.height = '0px';
      setItemNowOpen(false);
    }

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      if (isOpen) {
        updateHeight(); // Update height when content resizes
      }
    });

    if (contentElement) {
      resizeObserver.observe(contentElement);
      contentElement.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (contentElement) {
        resizeObserver.unobserve(contentElement);
        contentElement.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [isOpen]); // Dependency array


  return (
    <div
      id={`accordion-item-${id}`}
      className={`accordion-item ${isOpen ? 'open' : 'closed'}`}
    >
      <div className="accordion-head" onClick={onClick}>
        <Icon className={`label-icon ${iconClass ? iconClass : ""}`} descriptiveLabel={label} name={icon} />
        <span className='item-label'>{label}</span> 
        <Icon className="accordion-action" descriptiveLabel="Accordion Toggle" name={`accordion_${isOpen ? 'close' : 'open'}`} />
      </div>
      <div className='accordion-body' ref={contentRef}>
        <div className='accordion-body-content'>
          {children}
        </div>
      </div>
    </div>
  );
};

export { AccordionGroup, AccordionItem };
