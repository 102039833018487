import React, { useContext, useEffect, useState } from 'react';
import './Incentives.scss';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';


const Incentives = ({ planDetails, eligibilityIsConfirmed }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    let content;

    useEffect(() => {
        console.debug(`Incentive: ${JSON.stringify(planDetails?.electricityContract?.incentives)}`)
    })

    if (planDetails.electricityContract.incentives) {
        content = planDetails.electricityContract.incentives.map((incentive, index) => {
            return (
                <div key={index}>
                    <div className='incentiveHeader'>
                        <h3>{incentive.displayName}</h3>
                        {incentive.category != 'OTHER' &&
                            <span className='chip'>
                                {incentive.category == 'GIFT' ? 'Gift' : 'Account credit'}
                            </span>
                        }
                    </div>
                    <p>{incentive.description}</p>
                    {
                        incentive.eligibiblity &&
                        <p className='eligibility'>Note: {incentive.eligibility}</p>
                    }
                </div>
            )
        })
    } else {
        content = <p>No incentives</p>
    }
    return (
        <div className="breakdownIncentives">
            {content}
            {planDetails.planId != 'current_plan' &&
                <div className='inlineCtaGroup'>
                            {eligibilityIsConfirmed ?
                                <Button type="primary" className={`switch-now`} label="Switch now" href="#switch-via-email" clickHandler={() => setSelectedItem('switch-via-email')} />
                                :
                                <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} />
                            }
                 </div>
            }
        </div>
    );
};


export default Incentives;