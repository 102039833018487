import React, { useContext, useEffect, useState } from 'react';
import './CurrentPlanHero.scss';
import { DataContext } from '../../utils/DashboardDataContext';
import HeroGauge from '../Dashboard/HeroGauge';
import { HintButton } from '../Common/Hints';
import { StarRating } from '../Common/StarRating';
import Button from '../Common/Button';

const CurrentPlanHero = () => {
    const { planData, currentPlan, servicePoint, dataIsLoading, usageProfile, dashView, setDashView, energySavingSuggestions, displayAddress } = useContext(DataContext);
    const userLocale = navigator.language;


    //Costs
    const currentPlanCosts = planData.find(plan => plan.planId == 'current_plan');
    const annualCost = currentPlanCosts ? (currentPlanCosts.consumerCosts.total ? currentPlanCosts.consumerCosts.total / 100 : '?') : 0; // Divide by 100 = dollars
    const indexOfCurrentPlan = planData.findIndex(plan => plan.planId == 'current_plan');
    //const indexOfPreviousCosts = planData.findIndex(plan => plan.planId == 'actual_costs');
    const countOfBetterPlans = indexOfCurrentPlan;
    const minPlanCost = findItemWithSmallestTotalCost(planData)?.consumerCosts?.total / 100; //Ordered least -> most expensive, so first plan will be cheapest
    const maxPlanCost = findItemWithLargestTotalCost(planData)?.consumerCosts?.total / 100; //Ordered least -> most expensive, so last plan will be most expensive
    const planCostRange = maxPlanCost - minPlanCost;
    const minPlanSavings = annualCost - minPlanCost;
    const annualCostPercentage = (minPlanSavings / planCostRange) * 100;

    function findItemWithSmallestTotalCost(array) {
        return array.reduce((minItem, currentItem) => {
            if (currentItem.consumerCosts && currentItem.consumerCosts.total < minItem.consumerCosts.total) {
                return currentItem;
            }
            return minItem;
        }, array[0]);
    }

    function findItemWithLargestTotalCost(array) {
        return array.reduce((maxItem, currentItem) => {
            if (currentItem.consumerCosts && currentItem.consumerCosts.total > maxItem.consumerCosts.total) {
                return currentItem;
            }
            return maxItem;
        }, array[0]);
    }

    useEffect(() => {
        console.debug(`CurrentPlan object`, currentPlan);
    }, []);

    return (
        <>
            <div id="heroSummary" className={`content-tile primary ${dataIsLoading ? 'skeleton' : ''}`}>
                <div id="heroHeader">
                    <h1>Your plan</h1>
                    <div id="siteLocation"><span className='siteAddress' title={displayAddress}>{displayAddress}</span></div>
                </div>

                <div id="heroInfo">
                    <HeroGauge percent={annualCostPercentage} valuePrefix="$" value={new Intl.NumberFormat(userLocale, { maximumFractionDigits: 0 }).format(parseInt(annualCost))} valueCaption="Your cost p.a." />
                    <div id="heroDetails">
                        <span id="siteRetailer"><img className='brand-logo' src={`/assets/images/retailerLogos/${currentPlan.planOverview?.retailerBrand}.svg`} /> {currentPlan.planOverview?.retailerBrandName}</span>
                        <div className='detailItem' id='customerRatingDetail'>
                            <span className="detailLabel" id='planNameLabel'>{currentPlan.planOverview?.displayName}</span>
                        </div>
                        <div className='detailItem' id='customerRatingDetail'>
                            <span className="detailLabel" id='customerRatingLabel'>Customer rating</span>
                            <span className='detailValue' id='customerRatingValue'>
                                {currentPlan.planDetail?.brandRating ?
                                    <>
                                        <StarRating id='brandRating' rating={currentPlan.planDetail?.brandRating} />
                                        <span className='ratingValue'>{currentPlan.planDetail?.brandRating}</span>
                                    </>
                                    :
                                    <span className='ratingValue unknown'>unknown</span>}
                                <HintButton topic="customerRating" />
                            </span>
                        </div>
                        <div className='detailItem' id='ecoRatingDetail'>
                            <span className="detailLabel" id='ecoRatingLabel'>Eco rating</span>
                            <span className='detailValue' id='ecoRatingValue'>
                                <StarRating id='ecoRating' rating={currentPlan.planDetail?.ecoRating} iconRated="eco" iconUnrated="eco" />
                                <span className='ratingValue'>{currentPlan.planDetail?.ecoRating}</span>
                                <HintButton topic="ecoRating" /></span>
                        </div>
                        <Button type="primary" id="planDetails" label="Plan details" icon="filter" href="#planDetailsHeading" clickHandler={() => console.log('Clicked')} />
                    </div>
                </div>
            </div>
            <div className={`content-tile secondary ${dataIsLoading ? 'skeleton' : ''}`}>
                <h1>Better plans available</h1>
                <p>We found <strong>{countOfBetterPlans}</strong> plans that would be better for you than this plan. This is from {planData.length} plans available to you.</p>
                <Button type="primary" id="betterPlans" label="Better plans" icon="filter" href="/" clickHandler={() => console.log('Clicked')} />
            </div>
        </>
    );
}

export default CurrentPlanHero;