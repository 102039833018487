const gstMultiplyer = 1.1;

export const formatCentsAsDollarsAddGst = (centsExGst, round = false) => {
    return formatAsString(cents2Dollars(centsExGst * gstMultiplyer), round);
}

export const formatCentsAsDollars = (cents, round = false) => {
    return formatAsString(cents2Dollars(cents), round);
}

export const formatCentsAddGst = (centsExGst, round = false) => {
    return formatAsString((centsExGst * gstMultiplyer), round);
}

export const formatCents = (cents, round = false) => {
    return formatAsString(cents);
}

function formatAsString(amount, round = false) {
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, { maximumFractionDigits: round ? 0 : 2 }).format(amount);
}

function cents2Dollars(cents) {
    return cents / 100;
}

export function formatDate(dateString) { //Format YYYY-MM-DD dates into DD Mon YYYY dates
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateParts = dateString.split("-"); // Splits the date string into [YYYY, MM, DD]

    // Convert the month from MM format (01-12) to Mon format (Jan-Dec)
    const monthIndex = parseInt(dateParts[1], 10) - 1; // Subtract 1 to convert month from 1-12 to 0-11
    const monthName = months[monthIndex];

    // Format the date as DD Mon YYYY
    const formattedDate = `${parseInt(dateParts[2], 10)} ${monthName} ${dateParts[0]}`;

    return formattedDate;
}