import React, { useContext } from 'react';
import './SavingTip.scss';

const SavingTip = ({ tipDetails }) => {
    return (
        <div className='card'>
            <h3 className='topic'>{tipDetails.topic}</h3>
            <span className='subTopic'>{tipDetails.subTopic}</span>
            <img src={`/assets/images/${tipDetails.image}`} alt="Image for saving tip" />
            <p className='actionSummary'>{tipDetails.actionSummary}</p>
            <a className='button secondary' href="#">Learn more</a>
        </div>
    )
};

export default SavingTip;