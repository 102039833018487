import React, { useContext, useEffect, useState } from 'react';
import './BillsHero.scss';
import HeroGauge from '../Dashboard/HeroGauge';
import { HintButton } from '../Common/Hints';
import Button from '../Common/Button';
import { BillingContextProvider } from '../../utils/BillingContext';
import { DataContext } from '../../utils/DashboardDataContext';
import { formatDate } from '../../utils/utils';


export const BillsHero = ({ billingData }) => {
    const { planData, currentPlan, servicePoint, dataIsLoading, usageProfile, dashView, setDashView, energySavingSuggestions, displayAddress } = useContext(DataContext);
    const userLocale = navigator.language;

    const currentBalance = parseFloat(billingData?.account?.accountBalance);
    const mostRecentInvoiceEvent = billingData?.events?.find(event => event[1].billingEvent == 'invoice');
    const mostRecentInvoiceDue = mostRecentInvoiceEvent ? (mostRecentInvoiceEvent[1].dueDate ?? 'unknown') : 'unknown';
    const mostRecentInvoiceDueDate = mostRecentInvoiceDue == 'unknown' ? 'unknown' : formatDate(mostRecentInvoiceDue);
    const daysFromDueDate = daysUntilDue(mostRecentInvoiceDue);
    const minDaysFromDue = 14;
    const maxDaysFromDue = -14;
    const rangeOfDaysFromDue = Math.abs(maxDaysFromDue - minDaysFromDue);
    const mediumThresholdDays = 3; //days from due
    const mediumThresholdPercentage = (Math.abs(mediumThresholdDays - minDaysFromDue) / rangeOfDaysFromDue) * 100;
    const highThresholdDays = 0; //days from due
    const highThresholdPercentage = ((Math.abs(highThresholdDays - minDaysFromDue) / rangeOfDaysFromDue) * 100) + 1; //+1 to make 0 days be medium level instead of high level
    const clampedDaysFromDueDate = Math.max(maxDaysFromDue, Math.min(minDaysFromDue, daysFromDueDate));
    let gaugePercentage = (Math.abs(clampedDaysFromDueDate - minDaysFromDue) / rangeOfDaysFromDue) * 100;

    let dueInDescriptor = daysFromDueDate > 0 ? `Due in ${daysFromDueDate} day${daysFromDueDate != 1 ? 's' : ''}` : daysFromDueDate == 0 ? 'Due today' : `${Math.abs(daysFromDueDate)} day${Math.abs(daysFromDueDate) != 1 ? 's' : ''} overdue`;
    if (currentBalance <= 0) {
        gaugePercentage = 0;
        dueInDescriptor = '';
    } else if(mostRecentInvoiceDue == 'unknown') {
        gaugePercentage = 50;
        dueInDescriptor = 'Unknown due date';
    }

    console.debug(`Billing data ${JSON.stringify(billingData)}`)

    return (
        <div id="heroSummary" className={`content-tile primary ${dataIsLoading ? 'skeleton' : ''}`}>
            <div id="heroHeader">
                <h1>Your balance</h1>
                <div id="siteLocation"><span className='siteAddress' title={displayAddress}>{displayAddress}</span></div>
            </div>

            <div id="heroInfo">
                <HeroGauge percent={gaugePercentage} value={new Intl.NumberFormat(userLocale, { maximumFractionDigits: 2 }).format(currentBalance)} valuePrefix="$" valueCaption="Outstanding balance" minLabel="Not due" maxLabel="Overdue" mediumThreshold={mediumThresholdPercentage} highThreshold={highThresholdPercentage} judgementLabel={dueInDescriptor} />
                <div id="heroDetails">
                    <span id="siteRetailer"><img className='brand-logo' src={`/assets/images/retailerLogos/${currentPlan.planOverview?.retailerBrand}.svg`} /> {currentPlan.planOverview?.retailerBrandName}</span>
                    {
                        currentBalance > 0 &&
                        <div className='detailItem' id='ecoRatingDetail'>
                            <span className="detailLabel">Due date</span>
                            <span className='detailValue'>{mostRecentInvoiceDueDate}</span>

                        </div>
                    }
                    <Button type="primary" id="planDetails" label="See bills" icon="filter" href="#planDetailsHeading" clickHandler={() => console.log('Clicked')} />
                </div>
            </div>
        </div>
    )
}


function daysUntilDue(dueDateString) {
    // Parse the due date and today's date
    const dueDate = new Date(dueDateString);
    const today = new Date();

    // Reset hours, minutes, seconds, and milliseconds for both dates to ensure accurate day difference calculation
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds and convert to days
    const differenceInMilliseconds = dueDate - today;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
}