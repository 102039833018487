import React, { useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    async function handleAuthResponse() {
      try {
        // This will exchange the code for tokens, if a code is present in the URL.
        const user = await getCurrentUser();

        console.log("From auth callback: Authenticated user:", user);

        amplitude.setUserId(user.username);
        console.debug(`Set the Amplitude userId as ${user.username}`)

        navigate('/'); // Redirect to a protected route or home
      } catch (error) {
        console.error('Error during authentication:', error);
        // Handle or redirect to an error page
        navigate('/error');
      }
    }

    handleAuthResponse();
  }, [navigate]);

  return <div>Processing authentication...</div>;
}

export default AuthCallback;
