import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import HeroSummary from '../components/Dashboard/HeroSummaryDash';
import TailoredSuggestions from '../components/Dashboard/TailoredSuggestions';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import axios from 'axios';
import { DataContext } from '../utils/DashboardDataContext';
import { PseudoUserProfileContext } from '../utils/PseudoUserProfileContext';
import * as amplitude from "@amplitude/analytics-browser";

const RetailerDHConsent = () => {
    const { authenticatedUser } = useContext(AuthContext);

    useEffect(() => {
        amplitude.track(`Consent page rendered`);
    }, [])

    if (authenticatedUser) {
        return (
            <Site>
                <PageHeaderBar />
                    <div className='description'>
                        <p>Once your electricity account is linked, you will get personalised insights, advice and a bunch of other great stuff regarding your electricity supply!</p>
                        <p>Only data that is needed to provide you with this service is collected.</p>
                        <p>Data is collected daily to give you up-to-date and accurate information.</p>
                        <p>Your data is kept within Australia, safe on our server cloud environment which meets ISO27001 information securitiy requirements and all requirements of the Australian government's CDR schedule 2 on information security controls</p>
                    </div>
                    <form id="retailerConsentUserId">
                        <label>Electricity retailer: <select><option>Origin Energy</option><option>AGL</option><option>Energy Australia</option></select></label>
                        <br />
                        <label>User ID: <input type="email"></input></label>
                        <br />
                        <button type="submit">Next</button>
                    </form>
                <PageFooter />
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default RetailerDHConsent;
