import React, { useState, useContext, useEffect, cloneElement, Children } from 'react';
import './SegmentedButton.scss'

const SegmentedButtonContext = React.createContext();

const SegmentedButton = ({ level, className, clickHandler, children }) => {
  const [selectedValue, setSelectedValue] = useState(children[0].props.value);
  const levelValue = level || 'primary';

  // Clone and modify children with additional props
  const modifiedChildren = Children.map(children, (child, index) => {
    // Add "start" class to the first child
    if (index === 0) {
      return cloneElement(child, { className: (child.props.className || '') + ' start' });
    }
    // Add "end" class to the last child
    else if (index === Children.count(children) - 1) {
      return cloneElement(child, { className: (child.props.className || '') + ' end' });
    }
    // Return child as is for other cases
    return child;
  });

  return (
    <SegmentedButtonContext.Provider value={{ selectedValue, setSelectedValue, clickHandler }}>
      <div className={`segmented-button ${levelValue} ${className ? className : ''}`}>
        {modifiedChildren}
      </div>
    </SegmentedButtonContext.Provider>
  );
};

const ButtonSegment = ({ text, value, icon, className }) => {
  const { selectedValue, setSelectedValue, clickHandler } = useContext(SegmentedButtonContext);
  
  return (
    <span
      id={`button-segment-${value}`}
      className={`button-segment ${selectedValue === value ? 'selected' : ''} ${className}`}
      onClick={() => {setSelectedValue(value); clickHandler(value)}}
    >
      {/* Placeholder for icon. You might want to replace this with an actual icon component or library */}
      <i className={`icon-${icon}`}></i> 
      {text}
    </span>
  );
};

export { SegmentedButton, ButtonSegment };
