import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SiteMenuItem.scss';


const SiteMenuItem = ({ image, selected, handleClick, children, href }) => {
    const navigate = useNavigate();

    return (
        <div className={`site-menu-item ${selected ? 'current' : ''}`} onClick={(event) => {handleClick(event); navigate(href);}}>
            <img className="icon" alt={image} src={`/assets/images/${image}.svg`} />
            <div className="label">
                {children}
            </div>
        </div>
    );
};

export default SiteMenuItem;
