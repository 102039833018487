import { useContext, useEffect, useState } from 'react';
import './GetStarted.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';
import { Button } from '@aws-amplify/ui-react';
import { HintButton } from '../Common/Hints';

export const GetStarted = () => {
    const { flowStep, gotoNextStep, gotoPrevStep, progressBarSteps } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    return (
        <div className='flowStepContent'>
            <div className='heading'>
                <h1 className='headline'>Unlock energy savings now</h1>
                <span className='byline'>To access our services, lets:</span>
            </div>
            <div className='getStartedSteps'>
                <div className='getStartedStep'>
                    <img src="/assets/images/consent-icon.svg" />
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            Consent
                        </h2>
                        <p className='stepDescription'>
                            to collection and proessing your energy data.
                        </p>
                    </div>
                </div>
                <div className='getStartedStep'>
                    <img src="/assets/images/connect-icon.svg" />
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            Connect
                        </h2>
                        <p className='stepDescription'>
                            to your provider to authorise data sharing with us
                        </p>
                    </div>
                </div>
                <div className='getStartedStep'>
                    <img src="/assets/images/confirm-icon.svg" />
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            Confirm
                        </h2>
                        <p className='stepDescription'>
                            to sharing your energy data with us
                        </p>
                    </div>
                </div>
            </div>
            <div className='cdrMention'>
                <p className='message'>
                    Secure data sharing via the Consumer Data Right (CDR)
                    <HintButton topic='cdrConsent' />
                </p>
                <img className="cdrLogo" src="/assets/images/CDR_Primary_RGB_Colour.svg" />
            </div>
            <div className='nav'>
                <button onClick={gotoNextStep} className='primary next'>Get started</button>
            </div>
        </div>
    )
};