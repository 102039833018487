import React, { useContext, useEffect, useState } from 'react';
import './SwitchNow.scss';
import { AccordionGroup, AccordionItem } from '../Common/AccordionGroup';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';


const PlanDetailsBreakdown = ({ planDetails, eligibilityIsConfirmed }) => {

    return (
        <div className="switch-now-section content-tile primary">
            <h1 id="switch-now">Switch Now</h1>
            <AccordionGroup>
                <AccordionItem id="switch-via-email" label="Switch via email" icon="email" iconClass="on-disc">
                    <SwitchViaEmail planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} />
                </AccordionItem>
                <AccordionItem id="switch-online" label="Switch online" icon="online" iconClass="on-disc">

                </AccordionItem>
            </AccordionGroup>
        </div>
    );
};

const SwitchViaEmail = ({ planDetails, eligibilityIsConfirmed }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);

    const emePlanId = planDetails.planId.split('@')[0];
    const emailBody = `Hi ${planDetails.brandName},%0D%0A
%0D%0A
I would like to sign up to the ${planDetails.displayName} plan, specifically the plan variant with AER plan ID of ${emePlanId}.%0D%0A
%0D%0A
I am looking forward to hearing from you soon!`

    const ctaHandler = (event) => {
        console.debug(`Clicked`)
    }

    return (
        <div className='switch-via-email'>
            <div className='switch-via-email-header'>
                <h3>Switching to {planDetails.brandName} with one click!</h3>
            </div>

            <p className='step'>We generate a switching request email to send to <strong>{planDetails.brandName}</strong></p>

            <div className='email-generator'>
                <span className='new-plan-name'>{planDetails.displayName}</span>
                <div className='plan-id-holder'>
                    <span className='new-plan-id'>{emePlanId}</span>
                    <span className='new-plan-id-label'>Plan ID</span>
                </div>
                {eligibilityIsConfirmed ?
                    <Button type='primary' icon='email' label='Open email' clickHandler={(event) => { ctaHandler(event); }} href={`mailto:newplans@example.energy?subject=Sign up for ${planDetails.displayName}&body=${emailBody}`} />
                    :
                    <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} />
                }

            </div>
            <p>Once you've send the email, <strong>{planDetails.brandName}</strong> will get in touch with you, they will also contact your current provider and organise for your existing plan to be cancelled.</p>
        </div>
    )
}

export default PlanDetailsBreakdown;