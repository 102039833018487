import React from 'react';
//import { List, SimpleListItem } from '@rmwc/list';
//import '@rmwc/list/styles';
import SiteMenuItem from './SiteMenuItem';
import './SiteMenuItems.scss';


const SiteMenuItems = ({children}) => {
  return (
    <div className="siteMenuItems">
        {children}
    </div>
    /*
    <List>
        <SimpleListItem
            graphic="star_border"
            text="Dashboard"
        />
    </List>
    */
    /*
    <ul id="siteMenuItems">
        <li>Dashboard</li>
        <li>Logout</li>
    </ul>
    */
  );
};

export default SiteMenuItems;