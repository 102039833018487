import React, { createContext, useState, useContext } from 'react';

const HintContext = createContext();

export const useHint = () => {
  return useContext(HintContext);
};

export const HintProvider = ({ children }) => {
  const [hintTopic, setHintTopic] = useState(null);

  return (
    <HintContext.Provider value={{ hintTopic, setHintTopic }}>
      {children}
    </HintContext.Provider>
  );
};