import { formatDate } from '../../utils/utils';
import Icon from '../Common/Icon';
import './InvoiceDetails.scss';

const touTypeDisplayMap = {
    "PEAK": "Peak",
    "OFF_PEAK": "Off-peak",
    "SHOULDER": "Shoulder",
    "OFF_PEAK_DEMAN_CHARGE": "Off-peak demand",
    "SHOULDER1": "Shoulder 1",
    "SHOULDER2": "Shoulder 2",
    "CONTROLLED_LOAD": "Controlled load",
    "SOLAR": "Solar",
    "AGGREGATE": "Aggregate",
    "ALL_DAY": "All day"
}

export const InvoiceDetails = ({ invoiceData }) => {
    const userLocale = navigator.language;

    //Group invoice transactions by category
    let usageTxns = [], clUsageTxns = [], exportTxns = [], supplyTxns = [], clSupplyTxns = [], otherTxns = [];
    invoiceData.transactions.forEach(txn => {
        switch (txn.category) {
            case 'SUPPLY': {
                supplyTxns.push(txn);
            }; break;
            case 'CL_SUPPLY': {
                clSupplyTxns.push(txn);
            }; break;
            case 'IMPORT': {
                usageTxns.push(txn);
            }; break;
            case 'CL_IMPORT': {
                clUsageTxns.push(txn);
            }; break;
            case 'EXPORT': {
                exportTxns.push(txn);
            }; break;
            default: {
                otherTxns.push(txn);
            }; break;
        }
    });


    return <>
        <div className="invoice-date-range">Invoice #{invoiceData.invoiceNumber}: &nbsp;&nbsp; {formatDate(invoiceData.period.startDate)} - {formatDate(invoiceData.period.endDate)}</div>
        <div className="invoice-line-item invoice-total">
            <div className="line-item-label">Total amount</div>
            <div className="line-item-value">
                <div className="line-item-value-direction">
                    <Icon name={`${invoiceData.invoiceAmount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(invoiceData.invoiceAmount < 0) ? 'Debit' : 'Credit'} />
                </div>
                <div className="line-item-value-amount">${new Intl.NumberFormat(userLocale, { maximumFractionDigits: 2 }).format(parseFloat(invoiceData.invoiceAmount))}</div>
            </div>
        </div>

        {
            (exportTxns.length > 0) &&
            <>
                <div className="invoice-category-heading">Export</div>
                {
                    exportTxns.map((txn, index) => {
                        let txnDetails = txn[txn.transactionUType];
                        return (
                            <div className="invoice-line-item invoice-export">
                                <div className="line-item-label">{touTypeDisplayMap[txnDetails.timeOfUseType] ?? 'Solar'}: {Math.abs(txnDetails.usage)} {txnDetails.measureUnit == 'KWH' ? 'kWh' : txnDetails.measureUnit} @ {parseFloat(((txnDetails.amount * 100) / txnDetails.usage).toFixed(2))} c</div>
                                <div className="line-item-value">
                                    <div className="line-item-value-direction">
                                        <Icon name={`${txnDetails.amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(txnDetails.amount < 0) ? 'Debit' : 'Credit'} key={index} />
                                    </div>
                                    <div className={`line-item-value-amount ${txnDetails.amount < 0 ? 'debit' : 'credit'}`}>${Math.abs(txnDetails.amount)}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        }
        {
            (usageTxns.length > 0) &&
            <>
                <div className="invoice-category-heading">Usage</div>
                {
                    usageTxns.map((txn, index) => {
                        let txnDetails = txn[txn.transactionUType];
                        return (
                            <div className="invoice-line-item invoice-usage">
                                <div className="line-item-label">{touTypeDisplayMap[txnDetails.timeOfUseType] ?? 'General load'}: {Math.abs(txnDetails.usage)} {txnDetails.measureUnit == 'KWH' ? 'kWh' : txnDetails.measureUnit}{txnDetails.amount != 0 ? ` @ ${parseFloat(((txnDetails.amount * 100) / txnDetails.usage).toFixed(2))} c` : ''}</div>
                                <div className="line-item-value">
                                    <div className="line-item-value-direction">
                                        <Icon name={`${txnDetails.amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(txnDetails.amount < 0) ? 'Debit' : 'Credit'} key={index} />
                                    </div>
                                    <div className="line-item-value-amount">
                                        <div className={`line-item-value-amount ${txnDetails.amount < 0 ? 'debit' : 'credit'}`}>${Math.abs(txnDetails.amount)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {(clUsageTxns.length > 0) &&
                    clUsageTxns.map((txn, index) => {
                        let txnDetails = txn[txn.transactionUType];
                        return (
                            <div className="invoice-line-item invoice-cl-usage">
                                <div className="line-item-label">
                                    Controlled load: {Math.abs(txnDetails.usage)} {txnDetails.measureUnit == 'KWH' ? 'kWh' : txnDetails.measureUnit}{txnDetails.amount != 0 ? ` @ ${parseFloat(((txnDetails.amount * 100) / txnDetails.usage).toFixed(2))} c` : ''}
                                </div>
                                <div className="line-item-value">
                                    <div className="line-item-value-direction">
                                        <Icon name={`${txnDetails.amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(txnDetails.amount < 0) ? 'Debit' : 'Credit'} key={index} />
                                    </div>
                                    <div className="line-item-value-amount">
                                        <div className={`line-item-value-amount ${txnDetails.amount < 0 ? 'debit' : 'credit'}`}>${Math.abs(txnDetails.amount)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        }
        {
            (supplyTxns.length > 0) &&
            <>
                <div className="invoice-category-heading">Supply</div>
                {
                    supplyTxns.map((txn, index) => {
                        let txnDetails = txn[txn.transactionUType];
                        let daysCount = txnDetails.measureUnit == 'DAYS' ? txnDetails.usage : daysBetweenDates(txnDetails.startDate, txnDetails.endDate);
                        return (
                            <div className="invoice-line-item invoice-supply">
                                <div className="line-item-label">{daysCount} days @ {parseFloat(((txnDetails.amount * 100) / daysCount).toFixed(2))} c</div>
                                <div className="line-item-value">
                                    <div className="line-item-value-direction">
                                        <Icon name={`${txnDetails.amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(txnDetails.amount < 0) ? 'Debit' : 'Credit'} key={index} />
                                    </div>
                                    <div className="line-item-value-amount">
                                        <div className={`line-item-value-amount ${txnDetails.amount < 0 ? 'debit' : 'credit'}`}>${Math.abs(txnDetails.amount)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {(clSupplyTxns.length > 0) &&
                    clSupplyTxns.map((txn, index) => {
                        let txnDetails = txn[txn.transactionUType];
                        let daysCount = daysBetweenDates(txnDetails.startDate, txnDetails.endDate);
                        return (
                            <div className="invoice-line-item invoice-cl-supply">
                                <div className="line-item-label">{daysCount} days @ {parseFloat(((txnDetails.amount * 100) / daysCount).toFixed(2))} c</div>
                                <div className="line-item-value">
                                    <div className="line-item-value-direction">
                                        <Icon name={`${txnDetails.amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(txnDetails.amount < 0) ? 'Debit' : 'Credit'} key={index} />
                                    </div>
                                    <div className="line-item-value-amount">
                                        <div className={`line-item-value-amount ${txnDetails.amount < 0 ? 'debit' : 'credit'}`}>${Math.abs(txnDetails.amount)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        }
        {
            (otherTxns.length > 0) &&
            <>
                <div className="invoice-category-heading">Other</div>
                {
                    otherTxns.map((txn, index) => {
                        let txnDetails = txn[txn.transactionUType];
                        return (
                            <div className="invoice-line-item invoice-other">
                                <div className="line-item-label">{txnDetails.description}</div>
                                <div className="line-item-value">
                                    <div className="line-item-value-direction">
                                        <Icon name={`${txnDetails.amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={(txnDetails.amount < 0) ? 'Debit' : 'Credit'} key={index} />
                                    </div>
                                    <div className="line-item-value-amount">
                                        <div className={`line-item-value-amount ${txnDetails.amount < 0 ? 'debit' : 'credit'}`}>${Math.abs(txnDetails.amount)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        }

    </>
}

function daysBetweenDates(dateStr1, dateStr2) {
    // Parse the dates
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days (1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Return the absolute value (in case dateStr2 is before dateStr1)
    return (Math.abs(Math.round(differenceInDays)) + 1);
}

function formatUsageLineItemLabel(txnDetails) {
    const usage = txnDetails.usage ?? txnDetails.rate; //'rates' used by AGL for demand charges
    return `${touTypeDisplayMap[txnDetails.timeOfUseType] ?? 'General load'}: ${Math.abs(usage)} ${txnDetails.measureUnit == 'KWH' ? 'kWh' : txnDetails.measureUnit}${txnDetails.amount != 0 ? ` @ ${parseFloat(((txnDetails.amount * 100) / usage).toFixed(2))} c` : ''}`
}