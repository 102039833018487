import React, { useContext } from 'react';
import './TailoredSuggestions.scss';
import PlanList from './PlanList';
import { DataContext } from '../../utils/DashboardDataContext';
import SavingTips from './SavingTips';
import { HintButton } from '../Common/Hints'
import { ButtonSegment, SegmentedButton } from '../Common/SegmentedButton';

const TailoredSuggestions = () => {
    const { planData, energySavingSuggestions, planSortBy, setPlanSortBy, dataIsLoading, dashView } = useContext(DataContext);

    const countOfSavingTips = energySavingSuggestions?.count || 0;

    const planComparisonBlock =
        <>
            <div id="tailoredSuggestionsHeader">
                <div className='rowContainer'>
                    <div id="tailoredSuggestionsHeading">
                        <h2 id="betterPlansHeading">Better plans<HintButton topic="betterPlans" /></h2>
                        <span className='subline'>Sort by</span>
                    </div>
                </div>
                <SegmentedButton level="secondary" clickHandler={(selectedValue) => setPlanSortBy(selectedValue)}>
                    <ButtonSegment text="Cheapest" value="price" icon="check" />
                    <ButtonSegment text="Greenest" value="greenPower" icon="electricLeaf" />
                </SegmentedButton>
                <div className='columnTitles'>
                    <span className='columnTitle'>Cheapest {planSortBy === 'greenPower' ? 'Green Power ' : ''}plans for you</span>
                    <span className='columnTitle'>Est. Savings</span>
                </div>

            </div>
            <PlanList />
        </>

    const energySavingSuggestionsBlock =
        <>
            <div id="tailoredSuggestionsHeader">
                <div className='rowContainer'>
                    <div id="tailoredSuggestionsHeading">
                        <h2 id="saveEnergyHeading">Save energy<HintButton topic="saveEnergy" /></h2>
                        <span className='subline'></span>
                    </div>
                </div>
            </div>
            {countOfSavingTips > 0 ? <SavingTips /> : <p>No energy saving tips are available at the moment 😔</p>}
        </>

    return (
        <div id="tailoredSuggestions" className={`content-tile primary ${dataIsLoading ? 'skeleton' : ''}`}>
            {dashView === 'cost' ? planComparisonBlock : energySavingSuggestionsBlock}
        </div>
    );
};

export default TailoredSuggestions;