import { useContext, useEffect, useState } from 'react';
import './Consent.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';
import Button from '../Common/Button';
import { HintButton } from '../Common/Hints';

export const Consent = () => {
    const { flowStep, gotoNextStep, gotoPrevStep, selectedProvider, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);
    const [revealOpen, setRevealOpen] = useState(false);

    useEffect(() => {

    }, []);

    const dataClusterInfoHandler = (event) => {
        console.debug(`Data cluster info button clicked`)
    }

    const consentMgmtReveal = (target) => {
        console.debug(`Reveal clicked`);
        setRevealOpen(!revealOpen);
    }

    return (
            <div className='flowStepContent consentContent'>
                <div className='heading'>
                    <h1 className='headline'>Share your energy data</h1>
                    <span className='byline'>Automised needs access to your data to help cut your costs</span>
                </div>
                <div className="consentDataClusters">
                    <h2>We automatically collect:</h2>
                    <ul className='dataClustersList'>
                        <li>Name, occupation, contact details <HintButton topic="consentPersonalDeets" /></li>
                        <li>Account and plan details <HintButton topic="consentAccountDeets" /></li>
                        <li>Concessions and assistance <HintButton topic="consentConcessions" /></li>
                        <li>Payment preferences <HintButton topic="consentPaymentDeets" /></li>
                        <li>Billing payments and history <HintButton topic="consentBillingHistory" /></li>
                        <li>Electricity usage <HintButton topic="consentUsageHistory" /></li>
                        <li>Electricity connection and meter <HintButton topic="consentMeterDeets" /></li>
                        <li>Energy generation and storage <HintButton topic="consentDER" /></li>
                    </ul>
                    <p className='note' style={{display: 'none'}}>Historical information collected will date back 12 months.</p>
                </div>
                <div className='dataUses'>
                    <h2>This will be used to:</h2>
                    <ul>
                        <li>Personalise your energy savings</li>
                        <li>Analyse account and plan details</li>
                        <li>Analyse your payments and history</li>
                        <li style={{display: 'none'}}>Calculate the best plan for you</li>
                        <li style={{display: 'none'}}>Estimate your bills</li>
                        <li style={{display: 'none'}}>Analyse your usage, looking for other ways you can save</li>
                    </ul>
                </div>
                <div className={`consentManagement ${revealOpen ? 'open' : 'closed'}`}>
                    <h2>
                        <Icon name="gear" />
                        How can I manage my consent?
                        <Button type="reveal" icon="navigate_next" clickHandler={(event) => consentMgmtReveal(event.target)}/>
                    </h2>
                    <div className={`consentManagementContent`}>
                        <h4>You can revoke your consent at any time</h4>
                        <p>Access and manage your consents through your personal dashboard, where you can view who's using your data and choose to withdraw consent. Alternatively, for consent withdrawal, more information, or record copies, email <a className='emailAddress' href='mailto:support@automised.energy'>support@automised.energy</a>.</p>
                        <h3>This are the following steps:</h3>
                        <h4>Go to dashboard</h4>
                        <h4>Revoke consent</h4>
                        <h4 className='success'>Done</h4>
                    </div>
                </div>
                <div className='sharingPeriod'>
                    <div className='duration'>
                        <Icon name='date-range' />
                        Sharing period: &nbsp;&nbsp; 12 months
                    </div>
                    <div className='dateRange'>
                        dd/mm/YYYY - dd/mm/YYYY
                    </div>
                </div>
                <div className='nav'>
                    <button onClick={gotoNextStep} className='primary next'>Continue</button>
                </div>
                <div className='adrDeets'>
                    <div className='adrRego'>
                        <span className='adrBrand'>Adatree</span>
                        <span className='adrId'>Accredited Data Recipient: <a href="https://www.cdr.gov.au/find-a-provider?provider=ADRBNK000071">ADRBNK000071</a></span>
                    </div>
                    <img className="cdrLogo" src='/assets/images/CDR_Primary_RGB_Colour.svg' alt="Logo for the Consumer Data Right" />
                </div>
            </div>
    )
};