import React, { useContext } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import { CommonDataContext } from '../../utils/CommonDataContext';
import './PageHeaderBar.scss';
import Icon from './Icon';

const PageHeaderBar = ({headerBarItem}) => {
    const { authenticatedUser } = useContext(AuthContext);
    const { displayAddress } = useContext(CommonDataContext);

    return (
        <div id="pageHeaderBar">
            {headerBarItem &&
                <div className={`headerBarItem ${headerBarItem.className}`} title={headerBarItem.descriptiveLabel} onClick={headerBarItem.onClick}>
                    <Icon name={headerBarItem.icon} />
                </div>
            }
            <img className="logo" src='/assets/images/enbrace-logo.svg' />
            <div className="headerBarItem" id="notificationIndicator" title="No notifications" />
            <div className="headerBarItem" id="userProfile" title={authenticatedUser ? authenticatedUser.attributes.email : 'Not logged in'}>
                <span className='userInitials'>{authenticatedUser ? authenticatedUser.attributes.email[0].toUpperCase() : '?'}</span>
            </div>
        </div>
    );
};

export default PageHeaderBar;