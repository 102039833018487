import React, { useContext } from 'react';
import './PlanListItem.scss';
import { DataContext } from '../../utils/DashboardDataContext';
import { useNavigate } from 'react-router-dom';
import { formatCentsAsDollars } from '../../utils/utils';


const PlanListItem = ({ planId, brand, brandName, greenPowerMin, greenPowerMax, planName, deltaDollars, deltaPercentage, planCost, currentPlanCost }) => {
    const userLocale = navigator.language;
    const { postcode, planSortBy } = useContext(DataContext);
    const emePlanId = planId.split('@')[0];
    let greenPowerValue = 0;
    const navigate = useNavigate();

    switch (planSortBy) {
        case 'price': {
            greenPowerValue = greenPowerMin * 100;
        };
            break

        case 'greenPower': {
            greenPowerValue = greenPowerMax * 100;
        };
            break;
    }

    const openEMEWindow = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="planListItem" onClick={() => navigate(`/plans/${planId}?greenPower=${greenPowerValue / 100}`)}>
            <div className="plan-name-rating">
                <img className="provider-logo" src={`/assets/images/retailerLogos/${brand}.svg`} />
                <div className="content">
                    <div className="provider-green">
                        <div className="provider-name">{brandName}</div>
                        <div className="green-rating">
                            <img className="icon" src="/assets/images/electricLeaf.svg" />
                            <div className="text-wrapper">{greenPowerValue}%</div>
                        </div>
                    </div>
                    <div className="plan-name">{planName}</div>
                </div>
            </div>
            <div className="plan-costs-cost">
                <div className='delta'>
                    <img className={`directionIcon ${deltaDollars < 0 ? 'improvement' : 'deterioration'}`} src={`/assets/images/${deltaDollars < 0 ? 'improvement' : 'deterioration'}.svg`} />
                    <div className={`deltaValue ${deltaDollars < 0 ? 'improvement' : 'deterioration'}`}>
                        <span className='directionSign'>{deltaDollars < 0 ? '-' : '+'}</span>
                        <span className='currencySign'>$</span>
                        <span className='amount'>{formatCentsAsDollars(parseInt(Math.abs(deltaDollars)), true)}</span>
                    </div>
                </div>
                <div className='cost'>
                    <div className="costValue">Details</div>
                </div>
            </div>

        </div>
    );
};

export default PlanListItem;