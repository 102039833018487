import { useContext, useEffect, useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';
import './NewUser.scss'
import { ValueProp1, ValueProp2, ValueProp3 } from '../../components/Onboarding/ValueProps';
import { OnboardingContext } from '../../utils/OnboardingContext';
import { GetStarted } from '../../components/Onboarding/GetStarted';
import { ChooseProvider } from '../../components/Onboarding/ChooseProvider';
import { Consent } from '../../components/Onboarding/Consent';
import { Connecting } from '../../components/Onboarding/Connecting';
import { Confirm } from '../../components/Onboarding/Confirm';
import OnboardingBaseView from '../../components/Onboarding/OnboardingBaseView';

const NewUser = () => {
    const { authenticatedUser } = useContext(AuthContext);
    const flowStepsAr =
        [
            ['valueProp1', { component: <ValueProp1 />, progressBarStep: undefined }],
            ['valueProp2', { component: <ValueProp2 />, progressBarStep: undefined }],
            ['valueProp3', { component: <ValueProp3 />, progressBarStep: undefined }],
            ['getStarted', { component: <GetStarted />, progressBarStep: undefined }],
            ['chooseProvider', { component: <ChooseProvider />, progressBarStep: 'Provider' }],
            ['consent', { component: <Consent />, progressBarStep: 'Consent' }],
            ['connecting', { component: <Connecting />, progressBarStep: 'Connect' }],
            ['confirm', { component: <Confirm />, progressBarStep: 'Confirm' }],
        ]
    const flowStepsMap = new Map(flowStepsAr);

    const progressBarSteps = flowStepsAr
        .map(step => step[1].progressBarStep) // Extract progressBarStep from each value
        .filter(progressBarStep => progressBarStep !== undefined); // Filter out undefined values

    const flowStepIdsAr = flowStepsAr
        .map(step => step[0]);

    const [flowStep, setFlowStep] = useState(flowStepsAr[0][0]);
    const [selectedProvider, setSelectedProvider] = useState(undefined);
    const [inProp, setInProp] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.debug(`From NewUser: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)
    }, []);

    const gotoDashboard = () => {
        navigate('/');
    }

    const gotoNextStep = () => {
        let indexOfCurrentFlowStep = flowStepIdsAr.indexOf(flowStep);
        if ((indexOfCurrentFlowStep + 1) == flowStepIdsAr.length) { //At end of flowSteps. At last step. No more steps to go to.
            return;
        }
        //Update context to be the next step
        setInProp(false);
        setFlowStep(flowStepIdsAr[indexOfCurrentFlowStep + 1]);
    }

    const gotoPrevStep = () => {
        let indexOfCurrentFlowStep = flowStepIdsAr.indexOf(flowStep);
        if (indexOfCurrentFlowStep == 0) { //At start of flowSteps. At first step. No more steps to go to.
            return;
        }
        //Update context to be the next step
        setInProp(false);
        setFlowStep(flowStepIdsAr[indexOfCurrentFlowStep - 1]);
    }

    const gotoGetStarted = () => {
        setInProp(false);
        setFlowStep('getStarted');
    }

    useEffect(() => {
        //setInProp(true);
    }, [flowStep]);

    return (
        <OnboardingContext.Provider value={{ flowStepIdsAr, flowStep, gotoDashboard, gotoNextStep, gotoPrevStep, gotoGetStarted, progressBarSteps, currentProgressBarStep: flowStepsMap.get(flowStep).progressBarStep, selectedProvider, setSelectedProvider, inProp, setInProp }}>
            <OnboardingBaseView>
                {flowStepsMap.get(flowStep).component}
            </OnboardingBaseView>
        </OnboardingContext.Provider>
    )

};


export default NewUser;
