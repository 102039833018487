import React, { useContext, useEffect, useState } from 'react';
import { CommonDataContext } from '../../utils/CommonDataContext';
import { SessionContext } from '../../utils/SessionContext';
import * as EnbraceApis from '../../utils/enbraceAPIs'
import { PseudoUserProfileContext } from '../../utils/PseudoUserProfileContext';
import PlanDetailsBreakdown from '../PlanDetails/PlanDetailsBreakdown';
import CurrentPlanHero from './CurrentPlanHero';

export const CurrentPlanContent = () => {
    const { userProfile } = useContext(CommonDataContext);
    const { sessionContext, setSessionContext } = useContext(SessionContext);
    const { demoUserId, demoUserProfiles } = useContext(PseudoUserProfileContext);
    const [planDetailsAreLoading, setPlanDetailsAreLoading] = useState(false);
    const [planDetails, setPlanDetails] = useState(null);
    const [errorLoadingData, setErrorLoadingData] = useState(false);

    useEffect(() => {
        //if (!userProfile?.consumerId) return;

        if (!planDetailsAreLoading && (userProfile || demoUserId)) {

            setPlanDetailsAreLoading(true);
            const consumerId = userProfile?.consumerId ? userProfile.consumerId : demoUserProfiles[demoUserId].selectedConsumer;
            const accountId = userProfile?.consumerId ? userProfile.defaultServicePoint.accountId : demoUserProfiles[demoUserId].selectedConsumerAccount;
            const servicePointId = userProfile?.consumerId ? userProfile.defaultServicePoint.servicePointId : demoUserProfiles[demoUserId].selectedServicePoint;

            EnbraceApis.getIndividualPlanCosts(consumerId, accountId, servicePointId, 'current_plan', { annualised: true })
                .then(response => {
                    const planDetails = response;
                    setPlanDetails(planDetails);
                    setErrorLoadingData(false);
                    console.log(`API call finished`, planDetails)
                }).catch(error => {
                    setPlanDetails(null);
                    console.error('Error fetching plan details:', error);
                    setErrorLoadingData(true);
                })
                .finally(() => {
                    setPlanDetailsAreLoading(false);
                });
        }
    }, [userProfile, demoUserId])

    if (planDetails) {
        return (
            <>
                <CurrentPlanHero planDetails={planDetails.current_plan} />
                <PlanDetailsBreakdown planDetails={planDetails.current_plan} currentPlanCosts={planDetails.current_plan.consumerCosts.total} totalImports={(planDetails.current_plan.consumerCosts.referenceUsage.clImport || 0) + (planDetails.current_plan.consumerCosts.referenceUsage.generalImport || 0)} />
            </>
        )
    } else {
        return 'Loading...'
    }
}
