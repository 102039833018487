import React, { useRef, useEffect } from 'react';
import './PlanCostComparisonChart.scss';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const PlanCostComparisonChart = ({ item1, item2, price1, price2 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    Chart.defaults.font.family = 'Inter';

    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [item1, item2], // Item names
        datasets: [{
          label: 'Cost Comparison',
          data: [price1, price2], // Prices
          backgroundColor: [
            'rgba(253, 44, 81, 1)',
            'rgba(255, 255, 255, 1)'
          ],
          barThickness: 'flex', // Adjust bar thickness
          borderRadius: 6,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // Horizontal bar chart
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return value.toLocaleString('en-AU', { style: 'currency', currency: 'AUD', maximumFractionDigits:0 }); // Include a dollar sign in the ticks
              },
              maxTicksLimit: 5,
              color: 'rgba(76, 72, 98, 1)'
            },
            grid: {
              color: 'rgba(228, 221, 255, 1)',
              lineWidth: 0.5,
              drawTicks: false,
              drawBorder: false
            },
            border: {
              display: false
            }
          },
          y: {
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function (tooltipItem) {
                return '';
              },
              label: function (tooltipItem) {
                return tooltipItem.parsed.x.toLocaleString('en-AU', { style: 'currency', currency: 'AUD', maximumFractionDigits:0 });
              }
            }
          },
          legend: {
            display: false // Hide legend
          },
          datalabels: {
            color: ['rgba(251, 251, 255, 1)', 'rgba(31, 26, 59, 1)'], // Label text color
            font: {family: 'Inter', size: '16px', style: 'normal', weight: '600', lineHeight: '24px'},
            anchor: 'start', // Anchor on end of the bar
            align: 'end', // Align label above the bar
            offset: 24,
            formatter: (value, context) => {
              return `${context.chart.data.labels[context.dataIndex]}`; // Format label text to display
            }
          },
        }
      }
    });

    return () => {
      myChart.destroy();
    };
  }, [item1, item2, price1, price2]); // Redraw chart if any of these props change

  return (
    <div className='chartContainer'> {/* Adjust the width as needed */}
      <canvas ref={canvasRef} />
    </div>
  )
};

export default PlanCostComparisonChart;
