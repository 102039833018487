import logo from './logo.svg';
import './App.scss';
//import { MyComponent } from './components/sandbox/index';
//import Site from './layouts/Site';
import Dashboard from './pages/Dashboard';
import AuthCallback from './pages/AuthCallback';
import Login from './pages/Login';
import Logout from './pages/Logout';
import { setupAxiosInterceptors } from './utils/axiosSetup.js';
import ProtectedRoute from './utils/ProtectedRoute';
import { AuthProvider } from './utils/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PseudoUserProfileContext } from './utils/PseudoUserProfileContext';
import { useState, useEffect, useContext } from 'react';
import * as amplitude from "@amplitude/analytics-browser";
import RetailerDHConsent from './pages/RetailerDHConsent';
import FeedbackForm from './pages/FeedbackForm';
import Account from './pages/Account';
import Bills from './pages/Bills';
import Usage from './pages/Usage';
import Blog from './pages/Blog';
import CurrentPlan from './pages/CurrentPlan';
import PlanDetails from './pages/PlanDetails';
import { SessionProvider } from './utils/SessionContext';
import Help from './pages/Help.js';
import { CommonDataProvider } from './utils/CommonDataContext.js';
import NewUser from './pages/OnBoarding/NewUser.js';

//RUM Telemetry
import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_RUM_GUEST_ROLE_ARN,
    identityPoolId: process.env.REACT_APP_RUM_ID_POOL,
    endpoint: process.env.REACT_APP_RUM_ENDPOINT,
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID = process.env.REACT_APP_RUM_APP_ID;
  const APPLICATION_VERSION = process.env.REACT_APP_RUM_APP_VERSION;
  const APPLICATION_REGION = process.env.REACT_APP_RUM_APP_REGION;

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}


//APP
function App() {
  setupAxiosInterceptors();
  const [demoUserId, setDemoUserId] = useState(null);

  const demoUserProfiles = {
    a: {
      label: 'Demo A',
      selectedConsumer: 'demonstration.a.4988',
      selectedConsumerAccount: '70d2ffe7-fb79-47de-b7a2-104b5f0d2a80',
      selectedServicePoint: '52nrGb7ScNok1zqMYux0XQ=='
    },
    b: {
      label: 'Demo B',
      selectedConsumer: 'demonstration.b.9184',
      selectedConsumerAccount: 'ext_pmtduHbXUo45XQD9qDardtilyCr-yevxiCSW6ZfkVedo3zSZyZI0s1KZgXKDF21HwU1iH8jZy3YXI8i47S3QweyPcKK0xo-AIjjwK1v7qcE',
      selectedServicePoint: 'ext_pmtduHbXUo45XQD9qDardtilyCr-yevxiCSW6ZfkVedo3zSZyZI0s1KZgXKDF21HwU1iH8jZy3YXI8i47S3QweyPcKK0xo-AIjjwK1v7qcE'
    },
    c: {
      label: 'Demo C',
      selectedConsumer: 'demonstration.c.7785',
      selectedConsumerAccount: 'ext_s0-tLFDjA-n0mVt0S32VXruShmMOtRJah4lbGtg1Liql3wEQhJN6gu24HzgAsCxtsyvB-siVIJOa5cM-_qdZl_SSdj2aFIEetJ4D1Db86pg',
      selectedServicePoint: 'ext_s0-tLFDjA-n0mVt0S32VXruShmMOtRJah4lbGtg1Liql3wEQhJN6gu24HzgAsCxtsyvB-siVIJOa5cM-_qdZl_SSdj2aFIEetJ4D1Db86pg'
    }
  }


  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }, []);


  return (
    <SessionProvider value={{ foo: "bar" }}>
      <PseudoUserProfileContext.Provider value={{ demoUserId, setDemoUserId, demoUserProfiles }}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/authCallback" element={<AuthCallback />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/retailerConsent" element={<ProtectedRoute component={RetailerDHConsent} />} />
            <Route path="/feedback" element={<ProtectedRoute component={FeedbackForm} />} />
            <Route path="/help" element={<ProtectedRoute component={Help} />} />
            <Route path="/account" element={<ProtectedRoute component={Account} />} />
            <Route path="/usage" element={<ProtectedRoute component={Usage} />} />
            <Route path="/plan" element={<ProtectedRoute component={CurrentPlan} />} />
            <Route path="/blog" element={<ProtectedRoute component={Blog} />} />
            <Route path="/bills" element={<ProtectedRoute component={Bills} />} />
            <Route path="/" element={<ProtectedRoute component={Dashboard} />} />
            <Route path="/plans/:planId" element={<CommonDataProvider><ProtectedRoute component={PlanDetails} /></CommonDataProvider> } />
            <Route path="/newUser" element={<ProtectedRoute component={NewUser} />} />
          </Routes>
        </Router>
      </AuthProvider>
      </PseudoUserProfileContext.Provider>
    </SessionProvider>
  );
}

export default App;
