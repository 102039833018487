import React, { useContext, useEffect, useState } from 'react';
import './PostSwitch.scss';
import { AccordionGroup, AccordionItem } from '../Common/AccordionGroup';


const PlanDetailsBreakdown = ({ planDetails }) => {

    return (
        <div className="post-switch-section content-tile primary">
            <h1>After switching</h1>
            <AccordionGroup>
                <AccordionItem id="welcomme-package" label="Welcome package" icon="1" iconClass="on-disc">
                    
                </AccordionItem>
                <AccordionItem id="meter-reading" label="Meter reading" icon="2" iconClass="on-disc">
                    
                </AccordionItem>
                <AccordionItem id="plan-update" label="Update" icon="info" iconClass="on-disc">
                    
                </AccordionItem>
                <AccordionItem id="old-provider" label="Old provider" icon="info" iconClass="on-disc">
                    
                </AccordionItem>
            </AccordionGroup>
        </div>
    );
};


export default PlanDetailsBreakdown;