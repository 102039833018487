import { useContext, useEffect, useRef, useState } from 'react';
import './OnboardingBaseView.scss'
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';
import { CSSTransition } from 'react-transition-group';
import { HintProvider } from '../../utils/HintContext';
import { HintModal } from '../../components/Common/Hints';


const OnboardingBaseView = ({ children }) => {
    const { flowStepIdsAr, flowStep, gotoDashboard, gotoPrevStep, inProp, setInProp, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);
    const nodeRef = useRef(null);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const showProgressBarForSteps = ['getStarted', 'chooseProvider', 'consent', 'connecting', 'confirm'];
    const isFirstStep = flowStepIdsAr[0] == flowStep;

    useEffect(() => {
    }, []);

    return (
        <HintProvider>
            <div className="onboardingFlowScreen">
                <div className="pageBanner">
                    <img alt="Automised logo" src="/assets/images/logo.svg" />
                    {showProgressBarForSteps.includes(flowStep) && //Show progress bar only for the specified steps in the onboarding flow
                        <ProgressBar progressBarSteps={progressBarSteps} currentProgressBarStep={currentProgressBarStep} />
                    }
                    <div className="burgerMenu"><Icon name="close" className="on-disc" onClick={(event) => console.debug('Exit clicked')} /></div>
                </div>
                <div className='backNav'>
                    {!isFirstStep && //Don't show the back nav for the first step
                        <>

                            <button onClick={gotoPrevStep}><Icon name="arrow_back_long" descriptiveLabel="Go back" /> Back</button>
                        </>
                    }
                    <div className='progressSteps'>
                        {showProgressBarForSteps.includes(flowStep) && flowStep != 'getStarted' &&  //SHow progress steps only for the specified steps in the onboarding flow
                            <>
                                <strong>Step {showProgressBarForSteps.indexOf(flowStep)}</strong>/{showProgressBarForSteps.length-2}
                            </>
                        }
                    </div>
                </div>
                <CSSTransition in={inProp} nodeRef={nodeRef} timeout="1000" classNames="pageContent" addEndListener={() => setInProp(true)}>
                    <div className={`pageContent ${flowStep}`} ref={nodeRef}>
                        {children}
                    </div>
                </CSSTransition>
            </div>
            <div className='gutter' />
            <HintModal />
        </HintProvider>
    )
};

const ProgressBar = ({ progressBarSteps, currentProgressBarStep }) => {

    const currentProgressIndex = progressBarSteps.indexOf(currentProgressBarStep);

    return (
        <div className='progressBar'>
            <div className='lineIndicator'>
                {progressBarSteps?.map((step, index) => {
                    return (
                        <div key={index} className={`lineIndicatorStep ${index <= currentProgressIndex ? "engaged" : ""}`}></div>
                    )
                })}
            </div>
            <div className='progressSteps'>
                {progressBarSteps?.map((step, index) => {
                    return (
                        <span key={index} className={`progressStep ${index <= currentProgressIndex ? "engaged" : ""}`}>{step}</span>
                    )
                })}
            </div>
        </div>
    )

}

export default OnboardingBaseView;
