import React, { useContext, useEffect, useState } from 'react';
import './SavingTips.scss';
import SavingTip from './SavingTip';
import { DataContext } from '../../utils/DashboardDataContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation';
import { HintButton } from '../Common/Hints';

const SavingTips = () => {
    var { energySavingSuggestions, servicePoint } = useContext(DataContext);

    const swiperConfig1 = {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: true,
        modules: [Pagination, Navigation],
        className: 'mySwiper',
        pagination: { clickable: true },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            1170: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            1650: {
                slidesPerView: 3,
                spaceBetween: 10
            }
        }
    }

    const swiperConfig2 = {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: true,
        modules: [Pagination, Navigation],
        className: 'mySwiper2',
        pagination: { clickable: true },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            1170: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            1650: {
                slidesPerView: 3,
                spaceBetween: 10
            }
        }
    }

    return (
        <div id="planList">
            <div className="planListItems">
                <div className='planListGroupHeader'>
                    <h2 className="plansListGroupHeading">Specifically for you <HintButton topic="tailoredSaving" /></h2>
                    <span className='subline'>Tailored suggestions from your data</span>
                    <div className='energySavingSuggestionPanel'>
                        <div className='cards'>
                            <Swiper {...swiperConfig1}>
                                {
                                    energySavingSuggestions.tailored.map((savingTip, index) => {
                                        return <SwiperSlide key={index}>
                                            <SavingTip tipDetails={savingTip} key={index} />
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='cardsNav'></div>
                    </div>
                </div>
                <div className='planListGroupHeader'>
                    <h2 className="plansListGroupHeading">General saving tips <HintButton topic="tailoredSaving" /></h2>
                    <span className='subline'>general suggestions</span>
                    <div className='energySavingSuggestionPanel'>
                        <div className='cards'>
                            <Swiper {...swiperConfig2}>
                                {
                                    energySavingSuggestions.general.map((savingTip, index) => {
                                        return <SwiperSlide key={index + 100}>
                                            <SavingTip tipDetails={savingTip} key={index} />
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='cardsNav'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SavingTips;