import React from 'react';
import './PageBody.scss';

const PageBody = ({ isMenuOpen, clickHandler, children, className }) => {
  return (
    <div id="pageBody" className={`${isMenuOpen ? 'disabled' : ''} ${className}`} onClick={() => clickHandler()} >
      {children}
    </div>
  );
};

export default PageBody;