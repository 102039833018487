import React, { useContext, useEffect, useState } from 'react';
import './GreenPower.scss';
import { formatCents, formatCentsAsDollars } from '../../utils/utils';
import Icon from '../Common/Icon';
import { SessionContext } from '../../utils/SessionContext';
import { HintButton } from '../Common/Hints';
import { ToggleGroup, ToggleOption } from '../Common/ToggleGroup';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';

const GreenPower = ({ planDetails, totalImports, currentPlanCosts, costDivisorMap, costDivisor = { level: 'yearly', value: 1 }, setGranularity, eligibilityIsConfirmed }) => {
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    const { sessionContext, setSessionContext } = useContext(SessionContext);
    const [selectedGreenPowerCharge, setSelectedGreenPowerCharge] = useState({ amount: 0, type: '', percentage: 0 });
    const [greenPowerCost, setGreenPowerCost] = useState(0);

    useEffect(() => {
        //greenPower.optionalPercentTiers.unshift({max: greenPower.intrinsicPercent * 100, amount: 0, type: ''});

        console.debug(`planDetails.greenPower: ${JSON.stringify(planDetails.greenPower)}`)
        console.debug(`totalImports: ${totalImports}`)
    }, []);

    useEffect(() => {
        console.debug(`GreenPower option changed: ${JSON.stringify(selectedGreenPowerCharge)}`)
        switch (selectedGreenPowerCharge.type?.toUpperCase()) {
            case 'PERKWH': {
                console.debug(`Total imports: ${totalImports}`)
                setGreenPowerCost(selectedGreenPowerCharge.amount * totalImports);
            }; break;

            case 'PERDAY': {
                setGreenPowerCost(selectedGreenPowerCharge.amount * 365);
            }; break;

            case '': {
                setGreenPowerCost(0);
            }; break;
        }
    }, [selectedGreenPowerCharge])

    if (!planDetails) return;

    let greenPower;
    if (planDetails.planId != 'current_plan') {
        greenPower = planDetails.enriched_greenPower;
    } else {
        //greenPower = planDetails.greenPowerCharges;
        let greenPowerChargesAr = planDetails.electricityContract?.greenPowerCharges;
        if (!greenPowerChargesAr || greenPowerChargesAr.length < 1) {
            greenPowerChargesAr = undefined;
        }
        let maxAvailable = 0;
        const greenPowerOptionsAr = greenPowerChargesAr?.map((gpCharge, index) => {
            if (gpCharge.scheme === 'GREENPOWER') {
                const type = gpCharge.type === 'FIXED_PER_UNIT' ? "perKWh" : "perDay";
                let optionsAr = gpCharge.tiers.map((tier, index) => {
                    if (parseInt(tier.percentGreen) > maxAvailable) maxAvailable = parseInt(tier.percentGreen);
                    return {
                        type: type,
                        amount: tier.amount,
                        max: tier.percentGreen
                    }
                })
                return optionsAr;
            }
        })
        greenPower = {
            minAvailable: planDetails.greenPower.minAvailable,
            maxAvailable: maxAvailable,
            intrinsicPercent: parseInt(planDetails.electricityContract.intrinsicGreenPower?.greenPercentage ?? 0),
            optionalPercentTiers: greenPowerOptionsAr ? greenPowerOptionsAr[0] : 0, //TODO: deal with multiple greenPowerOptionsAr elements, not just take the first one. Need an example where there are more than one elements, though...
        }
        /*
        {"minAvailable":0,"maxAvailable":1,"intrinsic":0,"optional":[{"type":"perKWh","amount":0.7000000000000001,"max":0.25},{"type":"perKWh","amount":1.4000000000000001,"max":0.5},{"type":"perKWh","amount":2.8000000000000003,"max":1}],"maxCost":14120.292666666648}
        */
    }
    console.debug(`GreenPower object`, greenPower)

    //if (!greenPower) return <p>There is no GreenPower available with this plan</p>;

    let comparisonPlanCosts = planDetails.consumerCosts.total;
    let planCostsDelta = currentPlanCosts - comparisonPlanCosts;
    let deltaDirection = (planCostsDelta < 0) ? 'negative' : 'positive';
    let granularPlanCostsDelta = planCostsDelta / costDivisor.value;
    let displayPlanCostsDelta = formatCentsAsDollars(parseInt(granularPlanCostsDelta), true);

    const setGreenPowerCharge = (radioButtonEl, amount, type, percentage) => {
        console.debug(`Radiobutton state: ${radioButtonEl.checked}`)
        if (selectedGreenPowerCharge.amount == amount && selectedGreenPowerCharge.type == type) { //Toggle time
            setSelectedGreenPowerCharge({ amount: 0, type: '', percentage: 0 });
            radioButtonEl.checked = false;
        } else {
            setSelectedGreenPowerCharge({ amount, type, percentage });
        }
    }

    return (
        <div className="breakdownGreenPower">
            <div className='greenPower'>
                <span className='greenPowerLabel'>
                    GreenPower <HintButton topic="greenPower" />
                </span>
                <span className={`greenPowerValue ${selectedGreenPowerCharge.percentage > 0 ? 'additional' : ''}`}>
                    {((greenPower?.intrinsicPercent ?? 0) + selectedGreenPowerCharge.percentage) * 100}%
                </span>
            </div>
            {(greenPower && (greenPower.optionalPercentTiers.length > 0 || greenPower.intrinsicPercent > 0)) && (planDetails.planId != 'current_plan') &&
                <div className='optionalGreenPower'>
                    <h3>
                        GreenPower {greenPower.optionalPercentTiers.length > 0 ? 'options' : 'included'}:
                    </h3>
                    <div className='greenPowerOptions'>
                        {greenPower.intrinsicPercent > 0 &&
                            <div className='greenPowerOption'>
                                <span className='optGreenPowerDesc'>
                                    <Icon name='Neutral' className="directionIndicator" descriptiveLabel='No charge' />
                                    <strong>{greenPower.intrinsicPercent * 100}%</strong> GreenPower complimentary
                                </span>
                            </div>
                        }

                        {greenPower.optionalPercentTiers.map((gpCharge, index) => {
                            return (
                                <label className='greenPowerOption' key={index}>
                                    <span className='optGreenPowerDesc'>
                                        <Icon name='Plus' className="directionIndicator" descriptiveLabel='Additional charge' />
                                        <strong>{gpCharge.max * 100}%</strong> GreenPower for <strong>{formatCents(gpCharge.amount)}c</strong> {gpCharge.type}
                                    </span>
                                    <input type="radio" name="optionalGreenPower" onClick={(event) => setGreenPowerCharge(event.currentTarget, gpCharge.amount, gpCharge.type, gpCharge.max)} />
                                </label>
                            )
                        })}
                    </div>
                </div>
            }
            {greenPower && greenPower.optionalPercentTiers.length > 0 && (planDetails.planId != 'current_plan') &&
                <>
                    <div className='greenPowerCalculations'>
                        <span className='boxHeading'>GreenPower Calculator</span>
                        <div className='greenPowerCostLineItem'>
                            <span className='lineItemLabel'>Savings</span>
                            <Icon name='Neutral' className="directionIndicator" descriptiveLabel='Savings from this plan' />
                            <span className='lineItemValue'>${formatCentsAsDollars(parseInt(granularPlanCostsDelta), true)}</span>
                        </div>
                        <div className={`greenPowerCostLineItem ${parseInt(greenPowerCost) > 0 ? 'additionalCost' : ''}`}>
                            <span className='lineItemLabel'>GreenPower</span>
                            <Icon name='Minus' className="directionIndicator" descriptiveLabel='Additional GreenPower cost' />
                            <span className='lineItemValue'>${formatCentsAsDollars(parseInt(greenPowerCost) / costDivisor.value, true)}</span>
                        </div>
                        <div className={`greenPowerCostLineItem totalLine ${(parseInt(granularPlanCostsDelta) - (parseInt(greenPowerCost) / costDivisor.value) < 0) ? 'additionalCost' : ''}`}>
                            <span className='lineItemLabel'>New savings</span>
                            <Icon name='Total' className="directionIndicator" descriptiveLabel='New savings from this plan' />
                            <span className='lineItemValue'>${formatCentsAsDollars(parseInt(granularPlanCostsDelta) - (parseInt(greenPowerCost) / costDivisor.value), true)}</span>
                        </div>
                    </div>
                    {setGranularity &&
                        <ToggleGroup level="primary" clickHandler={(selectedValue) => setGranularity(selectedValue)} selectedToggleValue={costDivisor.name}>
                            <ToggleOption text="Yearly" value="yearly" />
                            <ToggleOption text="Quarterly" value="quarterly" />
                            <ToggleOption text="Monthly" value="monthly" />
                        </ToggleGroup>
                    }
                </>
            }

            {planDetails.planId != 'current_plan' &&
                <div className='inlineCtaGroup'>
                            {eligibilityIsConfirmed ?
                                <Button type="primary" className={`switch-now`} label="Switch now" href="#switch-via-email" clickHandler={() => setSelectedItem('switch-via-email')} />
                                :
                                <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} />
                            }
                 </div>
            }
        </div>
    );
};


export default GreenPower;