
import './StarRating.scss'

export function StarRating({ id, rating, iconUnrated, iconRated }) {
    // Calculate the width of the filled stars (as a percentage of the parent's width)
    //rating=undefined;
    const iconWidth = 7; //Designated width of the icon for the purpose of looking filled
    const iconAllocationWidth = 20;
    const iconPaddingPerSide = (iconAllocationWidth - iconWidth) / 2;
    const numberOfFullStars = Math.trunc(rating ?? 0);
    const fractionalStar = (rating ?? 0) % 1;
    const fractionalStarWidth = fractionalStar * iconWidth;
    const clipEndPosition = iconPaddingPerSide + (numberOfFullStars * iconAllocationWidth) + fractionalStarWidth;
    const clippedPixels = `${clipEndPosition}px`;
    console.debug(`clipped percent: ${clippedPixels}, rating: ${rating}`)

    const iconUnratedHref = `#${iconUnrated ?? 'star_border'}`;
    const iconRatedHref = `#${iconRated ?? 'star'}`;

    return (
        <div className={`star-rating ${id}`}>
            <svg width="100" height="20" viewBox="40 0 100 20" style={{ display: 'block' }}>
                <clipPath id={`star-clip-${id}`}>
                    <rect x="40" width={clippedPixels} height="20" />
                </clipPath>

                {/* Empty stars in the background */}
                {Array.from({ length: 5 }, (_, i) => (
                    <use key={`empty-${i}`} xlinkHref={iconUnratedHref} className={`star-empty ${id}`} x={20 * i} y="0" />
                ))}

                {/* Filled stars, will be clipped using the star-clip path */}
                <g clipPath={`url(#star-clip-${id})`}>
                    {Array.from({ length: 5 }, (_, i) => (
                        <use key={`filled-${i}`} xlinkHref={iconRatedHref} className={`star-filled ${id}`} x={20 * i} y="0" />
                    ))}
                </g>
            </svg>
        </div>

    )
}
