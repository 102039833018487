import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import './FeedbackForm.scss';
import { CommonDataProvider } from '../utils/CommonDataContext';

const FeedbackForm = () => {
    const { authenticatedUser } = useContext(AuthContext);

    useEffect(() => {
        amplitude.track(`Feedback page rendered`);
    }, [])

    if (authenticatedUser) {
        return (
            <Site>
                <CommonDataProvider>
                    <PageHeaderBar />
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScDr5JkAKS_2hv4iMKLg638LxI994pV4ySt5dqMm-VePatv0g/viewform?embedded=true" width="100%" height="2000" frameborder="0" marginheight="0" marginwidth="0" style={{ alignSelf: "flex-start" }}>Loading feedback form…</iframe>
                    <PageFooter />
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default FeedbackForm;
