import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { SessionContext } from './SessionContext';


export const updateUserProfile = async (consumerId, userProfile) => {
    try {
        const API_URL = `${process.env.REACT_APP_API_URL}consumers/${consumerId}/profile`;
        const response = await axios.put(API_URL, userProfile);
        return response.data;
    } catch (error) {
        //Handle error
        console.error('Unable to update user profile. ', error);
        return error;
    }
}

export const getCurrentPlan = async (consumerId, accountId, servicePointId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan`)
        .then(response => {
            const currentPlanData = response.data;
            return currentPlanData;
        }).catch(error => {
            console.error('Error fetching info on current plan: ', error);
            throw new Error(`Error fetching info on current plan: ${error}`)
        })
        .finally(() => {
        });
}

export const getComparisonPlanCosts = async (consumerId, accountId, servicePointId, options) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan-costs?annualised=true&f=${options.excludePlansWithEligibility ? 'eligibility' : ''}&dedupe=true`)
        .then(response => {
            let comparisonPlansData = response.data;
            comparisonPlansData = comparisonPlansData.filter(plan => plan.planId != 'actual_costs'); //Remove actual costs plan. Not used for app.
            return comparisonPlansData;
        }).catch(error => {
            console.error('Error fetching info on comparison plans costs: ', error);
            throw new Error(`Error fetching info on comparison plans costs: ${error}`)
        })
        .finally(() => {
        });
}

export const getIndividualPlanCosts = async (consumerId, accountId, servicePointId, planId, options) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan-costs/${encodeURIComponent(planId)}?annualised=true`)
        .then(response => {
            const currentPlanData = response.data;
            return currentPlanData;
        }).catch(error => {
            console.error('Error fetching info on current plan: ', error);
            throw new Error(`Error fetching info on current plan: ${error}`)
        })
        .finally(() => {
        });
}

export const getBillingData = async (consumerId, accountId, servicePointId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/bills`)
        .then(response => {
            const billingData = response.data;
            return billingData;
        }).catch(error => {
            console.error('Error fetching billing data from API: ', error);
            throw new Error(`Error fetching billing data from API: ${error}`)
        })
        .finally(() => {
        });
}
