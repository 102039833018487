import React, { useContext } from 'react';

import './NotificationBar.scss';
import { SessionContext } from '../utils/SessionContext';

const NotificationBar = ({ isMenuOpen, closeMenuFn }) => {
  const { sessionContext } = useContext(SessionContext);

  if (sessionContext.notificationMessage) {
    return (
      <div id="notificationBar" className='open'>
        {sessionContext.notificationMessage}
      </div>
    );
  } else {
    return ('');
  }
};

export default NotificationBar;