import React, { useState, useEffect } from 'react';
import PageBody from './PageBody';
import SiteMenu from './SiteMenu';
import NotificationBar from './NotificationBar';
import { HintProvider } from '../utils/HintContext';
import { useLocation } from 'react-router-dom';

import './Site.scss';
import { HintModal } from '../components/Common/Hints';

const Site = ({ children, className }) => {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /*
  const scrollHandler = (e) => {
    const href = e.target.getAttribute('href');
    if (href && href.startsWith('#')) {
      e.preventDefault();
      if (href === '#') {
        // Scroll to the top of the page
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        const target = document.querySelector(href);
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    }
  };*/

  /*
  useEffect(() => {
    const anchors = document.querySelectorAll('a[href^="#"]');
    console.debug(`Found ${anchors.length} anchor links`)

    anchors.forEach(anchor => {
      console.debug(`Adding smooth scroll event handler for anchor link ${anchor}`)
      //anchor.addEventListener('click', scrollHandler);
    });

    // Cleanup: Remove event listeners on component unmount
    return () => {
      anchors.forEach(anchor => {
        //anchor.removeEventListener('click', scrollHandler);
      });
    };
  }, []);*/

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div id="site">
      <HintProvider>
        <NotificationBar />

        <img title="Open menu" alt="Open menu" src='/assets/images/show-menu.svg' id="siteMenuOpener" onClick={(e) => {
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }} />

        <SiteMenu isMenuOpen={isMenuOpen} closeMenuFn={() => setIsMenuOpen(false)} />
        <PageBody isMenuOpen={isMenuOpen} clickHandler={isMenuOpen ? () => { setIsMenuOpen(false) } : () => { }} className={className}>{children}</PageBody>
        <HintModal />
      </HintProvider>
    </div>
  );
};

export default Site;