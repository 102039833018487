import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataProvider } from '../utils/CommonDataContext';

const Usage = () => {
    const { authenticatedUser } = useContext(AuthContext);

    useEffect(() => {
        amplitude.track(`Usage page rendered`);
    }, [])

    if (authenticatedUser) {
        return (
            <Site>
                <CommonDataProvider>
                    <PageHeaderBar />
                    <h1>Your energy usage</h1>
                    <p>We will show details of your real-time live energy usage as well as your history here, including daily profiles, seasonal trends, and comparison with others.</p>
                    <p>If you would like to see this built out, complete the <a href='/feedback'>feedback form</a> so we know there is interest!</p>
                    <PageFooter />
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default Usage;
