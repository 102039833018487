import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import HeroSummary from '../components/Dashboard/HeroSummaryDash';
import TailoredSuggestions from '../components/Dashboard/TailoredSuggestions';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import axios from 'axios';
import { DataProvider, DataContext } from '../utils/DashboardDataContext';
import { PseudoUserProfileContext } from '../utils/PseudoUserProfileContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataProvider } from '../utils/CommonDataContext';

const Dashboard = () => {
  const { authenticatedUser, userProfile } = useContext(AuthContext);
  const { demoUserId, setDemoUserId } = useContext(PseudoUserProfileContext);
  //console.debug(`authenticatedUser: ${authenticatedUser}, up: ${userProfile}, demoUserId: ${demoUserId}`)

  if (authenticatedUser && ((userProfile && Object.keys(userProfile).length > 0) || demoUserId)) {
    return (
      <Site>
        <CommonDataProvider>
          <DataProvider>
            <PageHeaderBar />
            <HeroSummary />
            <TailoredSuggestions />
          </DataProvider>
        </CommonDataProvider>
        <PageFooter />
      </Site>
    );
  } else if (authenticatedUser && userProfile && (Object.keys(userProfile).length === 0 && userProfile.constructor === Object)) {
    setDemoUserId('a');
    return (
      <Site>
        <CommonDataProvider>
          <DataProvider>
            <PageHeaderBar />
            <div className='demoPlaceholder' style={{ textAlign: 'center', height: 75 + 'vh' }}>
              <h1>Welcome to Automised!</h1>
              <h3>Your energy decisions on auto-pilot</h3>
              <p>We couldn't find a data set for your login, but you can use demo data</p>
              <a href='#'> Use demo data</a>
            </div>
          </DataProvider>
        </CommonDataProvider>
        <PageFooter />
      </Site>
    );
  }
  else {
    return 'Authenticating...';
  }
};



export default Dashboard;