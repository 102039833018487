import { useContext, useEffect, useState } from 'react';
import './ChooseProvider.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import axios from 'axios';
import Icon from '../Common/Icon';

export const ChooseProvider = () => {
    const { flowStep, gotoNextStep, gotoPrevStep, selectedProvider, setSelectedProvider, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);
    const [providersAr, setProvidersAr] = useState(undefined);
    const [pageError, setPageError] = useState(undefined);

    useEffect(() => {
        if (providersAr) return;

        setSelectedProvider(undefined); //Reset the selection status when user navigates back to this step

        //Fetch list of providers from API
        console.debug(`Fetching list of providers`);

        axios.get(`${process.env.REACT_APP_API_URL}providers`)
            .then(response => {
                setProvidersAr(response.data);
                console.debug(`Found list of ${response.data.length} providers`)
            })
            .catch(error => {

            })
            .finally(() => {

            })

    }, []);

    useEffect(() => {
        console.log(`selectedProvider changed to ${selectedProvider}`)
        if (selectedProvider) {
            setPageError(undefined); //Clear error if provider has been selected
        }
    }, [selectedProvider])

    const continueNav = () => {
        //Validate
        console.debug(`${selectedProvider}`)

        if (!selectedProvider) {
            setPageError(`Please select a provider to continue`);
            return false;
        }

        //Continue
        gotoNextStep();
    }

    return (
        <div className='flowStepContent'>
            <div className='heading'>
                <h1 className='headline'>Choose your provider</h1>
            </div>
            <ProviderSelector providersAr={providersAr} setSelectedProvider={setSelectedProvider} />
            <div className='nav'>
                {pageError && <div className='validationError'>
                    <Icon name="error" className="on-disc" />
                    {pageError}
                </div>}
                <button onClick={continueNav} className='primary next'>Continue</button>
            </div>
        </div>
    )
};

const ProviderSelector = ({ providersAr, setSelectedProvider }) => {
    const [filteredProvidersAr, setFilteredProvidersAr] = useState(providersAr);

    const filterProviders = (filterString) => {
        //console.debug(`Filtering on ${JSON.stringify(filterString)}`)
        if (filterString) {
            setFilteredProvidersAr(providersAr.filter(provider => provider.brandName.toUpperCase().indexOf(filterString.toUpperCase()) > -1));
            console.debug(`DH array length = ${filteredProvidersAr.length}`)
        } else {
            setFilteredProvidersAr(providersAr);
        }
    }

    useEffect(() => {
        setFilteredProvidersAr(providersAr);
    }, [providersAr])

    return (
        <form className='providerSelectorForm'>
            <input type="text" className='providerSearch' placeholder="Find your provider" onChange={(event) => filterProviders(event.target.value)} />
            <fieldset className='providerSelection'>
                <div className='containerFade' />
                {filteredProvidersAr?.map((provider, index) =>
                    <ProviderForSelection dataholderBrand={provider.fromPRD.brand} dataholderBrandName={provider.fromPRD.brandName} setSelectedProvider={setSelectedProvider} dataholderBrandId={provider.dataHolderBrandId} key={index} />
                )}
            </fieldset>
        </form>
    )
}

const ProviderForSelection = ({ dataholderBrand, dataholderBrandName, setSelectedProvider, dataholderBrandId }) => {
    const [selected, setSelected] = useState(false);

    const handleSelection = (selectedValue) => {
        //console.debug(`Handling selection. Selected value: ${selectedValue}`)
        setSelectedProvider({dataholderBrandId, dataholderBrand, dataholderBrandName});
    }

    return (
        <label className={`providerForSelection ${selected ? 'selected' : ''}`}>
            <img src={`/assets/images/retailerLogos/${dataholderBrand}.svg`} className='providerLogo' alt={`Logo for ${dataholderBrandName}`} />
            <span className='providerName'>{dataholderBrandName}</span>
            <input type="radio" className='providerRadioButton' name='dhBrand' id={`dhBrand-${dataholderBrand}`} value={dataholderBrandId} onClick={(event) => handleSelection(event.target.value)} />
        </label>
    )
}