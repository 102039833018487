import { AccordionGroup, AccordionItem } from "../Common/AccordionGroup"
import { HintButton } from "../Common/Hints"
import { InvoiceDetails } from "./InvoiceDetails";
import { formatDate } from "../../utils/utils";
import { PaymentDetails } from "./PaymentDetails";

export const BillsHistory = ({ billingData }) => {
    if (!billingData) return '';

    //Order billing events by event date
    billingData.events.sort((a, b) => {
        if (a[0] < b[0]) {
            return 1;
        } else if (a[0] > b[0]) {
            return -1;
        } else {
            return 0;
        }
    })


    return (
        <div className="detailsBreakdown content-tile primary">
            <h2>Billing history<HintButton topic="betterPlans" /></h2>
            <span className='subline'>Last 12 months</span>
            <AccordionGroup>
                {
                    billingData && billingData?.events?.map((billingEvent, index) => {
                        console.debug(`Billing event: ${billingEvent}`)
                        return (
                            <AccordionItem id={billingEvent[1].billingEvent + index} label={`${formatDate(billingEvent[0])}`} icon={`${billingEvent[1].billingEvent == 'payment' ? 'dollar' : 'bills'}`} key={index} iconClass="on-disc">
                                {
                                    (billingEvent[1].billingEvent == 'payment' && billingEvent[1].transactions[0].payment) ?
                                        <PaymentDetails paymentData={billingEvent[1]} />
                                        :
                                        <InvoiceDetails invoiceData={billingEvent[1]} />
                                }
                            </AccordionItem>
                        )
                    })
                }
            </AccordionGroup>
        </div>
    )
}


