import React from 'react';
import SiteMenuNav from '../components/SiteMenu/SiteMenuNav';
import SiteMenuFooter from '../components/SiteMenu/SiteMenuFooter';

import './SiteMenu.scss';

const SiteMenu = ({ isMenuOpen, closeMenuFn}) => {
  return (
    <div id="siteMenu" className={`drawer${isMenuOpen ? 'Open' : 'Closed'}`}>
        <SiteMenuNav closeMenuFn={closeMenuFn} />
        <SiteMenuFooter />
    </div>
  );
};

export default SiteMenu;