import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataProvider } from '../utils/CommonDataContext';

const Account = () => {
    const { authenticatedUser } = useContext(AuthContext);

    useEffect(() => {
        amplitude.track(`Account page rendered`);
    }, [])

    if (authenticatedUser) {
        return (
            <Site>
                <CommonDataProvider>
                    <PageHeaderBar />
                    <h1>Your energy account</h1>
                    <p>We will show details of your account with your energy retailer here, including current balance, your registered contact details and any other information about your household that is registered with your retailer.</p>
                    <p>If you would like to see this built out, complete the <a href='/feedback'>feedback form</a> so we know there is interest!</p>
                    <PageFooter />
                </CommonDataProvider>
            </Site>
        );
    } else {
        return 'No user';
    }

};

export default Account;
