import React from 'react';
import './TariffScheduleChart.scss'; // Ensure your CSS is set up as before
import Icon from '../Common/Icon';

const TariffScheduleChart = ({ timeOfUseRates }) => {
  // Convert HHMM to half-hour blocks index
  const timeToIndex = (time) => {
    const hours = parseInt(time.substring(0, time.length - 2), 10) || 0;
    const minutes = time.length > 2 ? parseInt(time.substring(time.length - 2), 10) : (parseInt(time, 10) || 0);
    return hours * 2 + (minutes >= 30 ? 1 : 0);
  };

  // Expand "BUSINESS_DAYS" to actual weekdays
  const expandBusinessDays = (days) => {
    const businessDaysMap = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
    return days.flatMap(day => {
        switch (day) {
            case 'MONDAY': {
                return 'MON';
            } break;
            case 'TUESDAY': {
                return 'TUE';
            } break;
            case 'WEDNESDAY': {
                return 'WED';
            } break;
            case 'THURSDAY': {
                return 'THU';
            } break;
            case 'FRIDAY': {
                return 'FRI';
            } break;
            case 'SATURDAY': {
                return 'SAT';
            } break;
            case 'SUNDAY': {
                return 'SUN';
            } break;
            case 'BUSINESS_DAYS': {
                return businessDaysMap;
            } break;
            case 'PUBLIC_HOLIDAYS': {
              //Ignore - there are not enough to make a difference
              return [];
          } break;
          default: {
                return day;
            } break;
        }
    });
  };

  // Initialize a schedule for the entire week
  const schedule = {
    'MON': new Array(48).fill('off-peak'),
    'TUE': new Array(48).fill('off-peak'),
    'WED': new Array(48).fill('off-peak'),
    'THU': new Array(48).fill('off-peak'),
    'FRI': new Array(48).fill('off-peak'),
    'SAT': new Array(48).fill('off-peak'),
    'SUN': new Array(48).fill('off-peak')
  };

  // Process the timeOfUseRates data
  timeOfUseRates.forEach(rate => {
    rate.timeOfUse.forEach(period => {
      const days = expandBusinessDays(period.days);
      const startIndex = timeToIndex(period.startTime);
      const endIndex = timeToIndex(period.endTime);

      days.forEach(day => {
        for (let i = startIndex; i <= endIndex; i++) {
          schedule[day][i] = rate.type.toLowerCase().replace('_', '-'); // 'peak', 'shoulder', 'off-peak'
        }
      });
    });
  });

  const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  return (
    <div className="tariff-schedule">
      {daysOfWeek.map(day => {
        let previousCellClass = '';
        return (
        <div key={day} className="tariff-row">
          <span className="tariff-label">{day.charAt(0)}</span>
          {schedule[day].map((cellClass, index) => {
            let startEdge = false;
            let endEdge = !schedule[day][index + 1] || (cellClass != schedule[day][index + 1]);
            if (previousCellClass != cellClass) {
                startEdge = true;
                previousCellClass = cellClass;
            }

            return <div key={index} className={`tariff-cell ${cellClass} ${startEdge ? 'startEdge' : ''} ${endEdge ? 'endEdge' : ''}`} title={blockTo12HourTime(index)}></div>
            })}
        </div>
        )})}

        <div className="tariff-row">
            <span className="tariff-label"> </span>
            <div className='seasonsIcons'>
                <Icon name="night" descriptiveLabel="moon" />
                <Icon name="day" descriptiveLabel="sun" />
                <Icon name="night" descriptiveLabel="moon" />
            </div>
        </div>

    </div>
  );
};

function blockTo12HourTime(blockNumber) {
    let hours = Math.floor(blockNumber / 2);
    const minutes = (blockNumber % 2) * 30;
    const ampm = hours >= 12 ? 'pm' : 'am';
    
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
  
    return `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }
  
  

export default TariffScheduleChart;
