import React, { useState, useContext, useEffect, cloneElement } from 'react';
import './ToggleGroup.scss'

// Context to share the state between ToggleGroup and ToggleOption
const ToggleContext = React.createContext();

const ToggleGroup = ({ level, className, clickHandler, children, selectedToggleValue }) => {
  const defaultSelectedValue = selectedToggleValue || children[0].props.value;
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
  const levelValue = level || 'primary';

  useEffect(() => {
    //console.debug(`defaultSelectedValue: ${defaultSelectedValue}`)
    //console.debug(`selectedToggleValue: ${selectedToggleValue}`)
  }, [])

  useEffect(() => {
    //console.debug(`selectedValue: ${selectedValue}`)
  }, [selectedValue])

  useEffect(() => {
    if (!selectedToggleValue) return;
    //console.debug(`selectedToggleValue changed: ${selectedToggleValue}`)
    setSelectedValue(selectedToggleValue);
  }, [selectedToggleValue])

  return (
    <ToggleContext.Provider value={{ selectedValue, setSelectedValue, clickHandler }}>
      <div className={`toggle-group ${levelValue} ${className}`}>
        {children}
      </div>
    </ToggleContext.Provider>
  );
};

const ToggleOption = ({ text, value, icon }) => {
  const { selectedValue, setSelectedValue, clickHandler } = useContext(ToggleContext);
  
  return (
    <span
      id={`toggle-option-${value}`}
      className={`toggle-option ${selectedValue === value ? 'selected' : ''}`}
      onClick={() => {setSelectedValue(value); clickHandler(value)}}
    >
      {/* Placeholder for icon. You might want to replace this with an actual icon component or library */}
      <i className={`icon-${icon}`}></i> 
      {text}
    </span>
  );
};

export { ToggleGroup, ToggleOption };
